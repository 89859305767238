"use client";

import { useState, useEffect } from "react";
import Logo from "@/components/icons/Logo";
import Link from "next/link";
import { usePathname } from "next/navigation";
import For from "@/components/For";
import If from "@/components/If";
import Image from "@/components/Image";
import { Button } from "@/components/actions/Button";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { ChevronDown } from "react-feather";
import { useLenis } from "lenis/react";

const links = [
  { href: "/", label: "Home", hasDropdown: true },
  { href: "/who-we-are", label: "Who We Are" },
  { href: "/why-were-different", label: "Why We're Different" },
  {
    href: "/what-we-do",
    label: "What We Do",
    subNav: [
      { href: "/what-we-do#services", label: "Services & Accounts" },
      { href: "/what-we-do#projects", label: "Large Projects" },
      // { href: "#services", label: "Services & Accounts" },
    ],
  },
  { href: "/join-our-team", label: "Join Our team" },
];

const Dropdown = ({ onToggle, open }) => {
  const pathname = usePathname();
  return (
    <li className="relative">
      <button
        className={`flex gap-2 transition-all duration-300 font-medium  items-center ${
          open || pathname.includes("/communities")
            ? "text-orange"
            : "text-white hover:text-orange"
        }`}
        onClick={onToggle}
      >
        Communities{" "}
        <ChevronDown
          className={`${
            open ? "rotate-180" : ""
          } transition-all duration-300 ease-in-out`}
        />
      </button>
    </li>
  );
};

const LinkItem = ({ link }) => {
  const pathname = usePathname();
  return (
    <li>
      <Link
        className={`transition-all duration-300 whitespace-nowrap ${
          pathname.includes(link.href)
            ? "text-orange"
            : "text-white hover:text-orange"
        } ${
          link.isButton ? "px-6 py-3 border-2 broder-white rounded-full" : ""
        }`}
        href={link.href}
      >
        {link.label}
      </Link>
    </li>
  );
};

const MobileLinkItem = ({ link }) => {
  const pathname = usePathname();
  return (
    <li>
      <Link
        className={`transition-all duration-300 text-[3.5rem] lg:text-[6.5rem] text-right font-medium leading-none uppercase font-hero block w-full ${
          (link.alternateLink !== "/" &&
            pathname.includes(link.alternateLink)) ||
          (pathname === link.alternateLink && link.alternateLink === "/")
            ? "text-yellow"
            : "text-white hover:text-yellow"
        } `}
        href={link.alternateLink}
      >
        {link.title}
        {link.subNavCollection.items?.length > 0 && (
          <ul className="flex flex-col gap-4 mt-4">
            {link.subNavCollection.items.map((subLink) => (
              <li key={subLink.title}>
                <Link
                  className={`transition-all duration-300 text-[1.5rem] lg:text-[3rem] text-right font-medium leading-none uppercase font-hero block w-full ${
                    pathname.includes(subLink.alternateLink)
                      ? "text-yellow"
                      : "text-white hover:text-yellow"
                  } `}
                  href={subLink.alternateLink}
                  scroll={false}
                >
                  {subLink.title}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </Link>
    </li>
  );
};

export default function Nav({ nav = [] }) {
  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isLowerThanNav, setIsLowerThanNav] = useState(false);
  const lenis = useLenis();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = lenis?.scroll || 0;
      // console.log("currentScrollPos", currentScrollPos);
      const isScrollingDown = currentScrollPos > prevScrollPos;

      if (currentScrollPos > 100) {
        setIsLowerThanNav(true);
      } else {
        setIsLowerThanNav(false);
      }

      setIsHeaderVisible(!isScrollingDown);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    setOpen(false);
    setMobileOpen(false);
  }, [pathname]);

  return (
    <>
      {/* <div
        className={`fixed top-0 left-0 w-full z-[49] bg-nav-background nav-effect transition-all duration-300 ${
          isLowerThanNav ? "h-[95px]" : "h-[117px]"
        } ${isHeaderVisible || open ? "" : "transform -translate-y-full"}`}
      ></div> */}
      <header
        className={`main-nav fixed top-0 left-0 right-0 z-[15002] transition-all duration-300 w-[100dvw] ease max-w-full bg-black ${
          isLowerThanNav && !isHeaderVisible ? "retract" : ""
        }`}
      >
        <div className="grid nav-layout justify-between items-center py-4 max-w-full px-gutter relative z-[1]">
          <Link
            href="/"
            className="hover:text-yellow  transition-all duration-300 focus:outline-none text-white"
          >
            <span className="sr-only">Home</span>
            <Logo className="w-[300px] max-w-[80%]" />
          </Link>
          <div className="flex justify-end items-center gap-4">
            <button
              onClick={() => setMobileOpen(!mobileOpen)}
              className="flex flex-col md:flex-row justify-end gap-1 md:gap-4 items-center text-white hover:text-yellow transition-all duration-300 focus:outline-none"
            >
              <If condition={!mobileOpen}>
                <svg
                  viewBox="0 0 21 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[35px]"
                >
                  <rect width="20.0763" height="2.20022" fill="currentColor" />
                  <rect
                    y="10.1426"
                    width="20.0763"
                    height="2.20022"
                    fill="currentColor"
                  />
                  <path
                    d="M0 5.07227H20.0763V7.27249H0V5.07227Z"
                    fill="currentColor"
                  />
                </svg>
              </If>

              <If condition={mobileOpen}>
                <svg
                  height={22}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="1.55566"
                    y="0.224121"
                    width="20.0763"
                    height="2.20022"
                    transform="rotate(45 1.55566 0.224121)"
                    fill="white"
                  />
                  <rect
                    y="14.1963"
                    width="20.0763"
                    height="2.20022"
                    transform="rotate(-45 0 14.1963)"
                    fill="white"
                  />
                </svg>
              </If>

              <span className="text-white leading-none uppercase text-xs md:text-base hover:text-yellow  transition-all duration-300 focus:outline-none">
                Menu
              </span>
            </button>
          </div>
        </div>
      </header>
      <If condition={open}>
        <div className="fixed w-full h-full left-0 top-0 z-10 bg-dark-blue mix-blend-multiply do-animate-up delay-700"></div>
        <nav
          className="dropdown-nav fixed top-0 left-0 right-0 z-40 bg-transparent flex flex-col gap-8  mt-[150px] overflow-auto w-full h-full pb-50 main-nav do-animate-up delay-700"
          data-lenis-prevent
        ></nav>
      </If>
      <If condition={mobileOpen}>
        <div className="fixed w-full h-full left-0 top-0 z-[15000] bg-black do-animate-up delay-700"></div>
        <nav
          className="fixed top-0 left-0 right-0 z-[15001] bg-transparent flex flex-col gap-8  mt-[150px] overflow-auto w-full h-full pb-50 do-animate-up delay-700"
          data-lenis-prevent
        >
          <div className="flex flex-col gap-12 px-gutter h-max relative z-[1]">
            <ul className="flex flex-col gap-6 items-left mobile-links">
              <For
                each={nav?.menuItemsCollection?.items || []}
                render={(link) => {
                  return <MobileLinkItem link={link} />;
                }}
              />
            </ul>
            {/* <For
              each={communities}
              render={(comm) => {
                return (
                  <div key={comm.title} className="stack gap-6">
                    <Link href={`/communities/${comm.slug}`}>
                      <Image
                        src={comm.cardImage?.url}
                        alt={comm.title}
                        className="aspect-[622/400]"
                        imgClassName="rounded aspect-[622/400]"
                        maxWidth="600"
                      />
                    </Link>
                    <div>
                      <p className="text-white text-sm">
                        {comm.city}, {comm.state}
                      </p>
                      <Link
                        href={`/communities/${comm.slug}`}
                        className="text-white hover:text-orange transition-all duration-300"
                      >
                        {comm.title}
                      </Link>
                    </div>
                  </div>
                );
              }}
            /> */}
          </div>
          {/* <Button link="/communities" className="mx-auto relative z-[1]">
            View All Communities
          </Button> */}
        </nav>
      </If>
    </>
  );
}
